import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useStoreState } from "../../core/store/store.hook";
import { withStyles, createStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { skinSet } from "../../core/skin/skin.action";
import { parseISO } from "date-fns";

const StyledKeyboardDatePicker = withStyles(() =>
  createStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#FFFFFF55",
        },
      },
      "& .MuiInputLabel-root": {
        color: "#AAAAAAAA",
        "&.Mui-focused": {
          color: "white",
        },
      },
      "& .MuiOutlinedInput-inputMarginDense": {
        paddingTop: 18.5,
        paddingBottom: 18.5,
      },
      "& .MuiIconButton-root": {
        color: "white",
      },
      // "&. .MuiPaper-root": {
      //   backgroundColor: "#003300",
      //   "&.MuiDialog-paper": {
      //     backgroundColor: "#003300",
      //   },
      // },
      // "& .MuiDialog-paper": {
      //   backgroundColor: "#003300",
      // },
      // "& .MuiPickers-toolbar": {
      //   toolbar: {
      //     backgroundColor: "green",
      //   },
      // },
      // "& .MuiPickersModal-dialogRoot": {
      //   backgroundColor: "green",
      // },
    },
    // h6: {
    //   "& .MuiTypography-subtitle1": {
    //     color: "yellow",
    //     fontSize: 40,
    //     lineHeight: 1,
    //   },
    //   "& .MuiTypography-root": {
    //     margin: 200,
    //   },
    //   "& .MuiPickersToolbarText-toolbarTxt": {
    //     color: "yellow",
    //   },
    // },
  })
)(KeyboardDatePicker);

export const ElementDate = ({
  label,
  onChange,
}: {
  label: string;
  onChange: (event: Date) => void;
}) => {
  const dispatch = useDispatch();
  const date = useStoreState((state) => state.form.formState.form.birthDate);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const skin = useStoreState((state) => state.skin.skin);
  // const [localValue, setLocalValue] = useState<Date>();
  // const [test, setTest] = useState();
  // useEffect(() => console.log(test), [test]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledKeyboardDatePicker
        // disableToolbar
        inputVariant="outlined"
        emptyLabel="..."
        variant="dialog"
        format="dd/MM/yyyy"
        margin="dense"
        label={label}
        value={date && parseISO(date)}
        onChange={(event) => {
          if (event?.valueOf()) {
            onChange(event);
          }
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        style={{
          marginLeft: 15,
          marginRight: 15,
        }}
        disableFuture
        open={openDialog}
        onClick={() => {
          dispatch(
            skinSet({
              ...skin,
              palette: {
                ...skin.palette,
                text: {
                  ...skin.palette.text!,
                  primary: openDialog ? "#ffffff" : "#000000",
                },
                primary: {
                  main: openDialog ? "#ffffff" : "#003300",
                },
              },
            })
          );
          setOpenDialog(!openDialog);
        }}
        onClose={() => {
          dispatch(
            skinSet({
              ...skin,
              palette: {
                ...skin.palette,
                text: {
                  ...skin.palette.text!,
                  primary: "#ffffff",
                },
                primary: {
                  main: "#ffffff",
                },
              },
            })
          );
          setOpenDialog(false);
        }}
        // helperText={"ERROR"}
        // onInput={(event) => console.log(event)}
      />
    </MuiPickersUtilsProvider>
  );
};
