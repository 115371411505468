export enum WSProtocol {
  Connect = 1, //login
  ConnectResponse = 2001,
  AccountUsernameUniqueResponse = 1101,
  AccountEmailUniqueResponse = 1102,
  AccountCreateResponse = 1100,
  AccountVerifyPhoneUnique = 205,
  AccountVerifyUsernameUnique = 200,
  AccountVerifyEmailUnique = 201,
  AccountPhoneUniqueResponse = 1108,
  AccountValidateReferralCodeResponse = 1109,
  AccountValidateReferralCode = 207,
  Version = 3, //prva poruka??
  AccountCreate = 5,
  Ping = 4,
  CheckPromoCode = 210,
  AccountRequestEmailVerification = 213,
  CheckPromoCodeResponse = 1106,
  BlockedAccountCreated = 1300,
  RequestPhoneCode = 211,
  RequestPhoneCodeResponse = 1211,
  SubmitPhoneCode = 212,
  SubmitPhoneCodeResponse = 1212,
  AppReadyToUse = 2002,
}

export interface Environment {
  apiUrl: string;
  wsUrl: string;
}
