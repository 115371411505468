import { emitActionMessage } from "./message.service";
import { RootAction } from "../store/store.types";
import { Middleware } from "redux";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const messagesMiddleware: Middleware = (store) => (next) => (
  action: RootAction
) => {
  emitActionMessage(action);
  return next(action);
};
