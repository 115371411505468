export function visitUrl(url: string) {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function visitUrlNew(url: string) {
  window.open(url);
  return;
}
