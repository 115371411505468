import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useOnMount } from "../../global/hooks/on-mount.hook";
import { StyledTextField } from "../form/form-element.component";

interface Option {
  code: string;
  value: string;
}

export const AutocompleteLocal = ({
  list,
  label,
  onSelect,
  style,
  paperStyle,
  defaultValue,
  disabled,
}: {
  list: Option[];
  label: string;
  onSelect: (selection: Option | null) => void;
  style?: React.CSSProperties;
  paperStyle?: React.CSSProperties;
  defaultValue?: string;
  disabled?: boolean;
}) => {
  const [value, setValue] = useState<Option | null>(null);
  const useStyles = makeStyles(() => ({
    paper: {
      ...paperStyle,
    },
  }));

  const classes = useStyles();

  useOnMount(() => {
    if (defaultValue) {
      setValue(
        list.find(
          (option) => option.code.toUpperCase() === defaultValue.toUpperCase()
        ) ?? null
      );
    }
  });

  return (
    <Autocomplete
      classes={classes}
      value={value}
      onChange={(event: any, newValue: Option | null) => {
        onSelect(newValue);
        setValue(newValue);
      }}
      clearOnEscape
      options={list}
      getOptionLabel={(option) => option.value}
      style={{ ...style }}
      renderInput={(params) => (
        <StyledTextField {...params} label={label} variant="outlined" />
      )}
      disableClearable={disabled}
    />
  );
};
