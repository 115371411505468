import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";
import { FORM } from "../../global/fallback/form";
import * as actions from "./skin.action";
import {
  Skin,
  SKIN_FETCH_BACKUP_FAIL,
  SKIN_FETCH_BACKUP_SUCCESS,
  SKIN_SET,
} from "./skin.constants";

type FormState = Readonly<{
  skin: Skin;
  loaded: boolean;
}>;

type FormAction = ActionType<typeof actions>;

const initialState: FormState = {
  skin: FORM,
  loaded: false,
};

export const skinReducer = combineReducers<FormState, FormAction>({
  skin: (state = initialState.skin, action) => {
    switch (action.type) {
      case SKIN_FETCH_BACKUP_SUCCESS:
        return action.payload;
      case SKIN_FETCH_BACKUP_FAIL:
        return FORM;
      case SKIN_SET:
        return action.payload ?? state;
      default:
        return state;
    }
  },
  loaded: (state = initialState.loaded, action) => {
    switch (action.type) {
      case SKIN_FETCH_BACKUP_SUCCESS:
        return true;
      case SKIN_FETCH_BACKUP_FAIL:
        return true;
      case SKIN_SET:
        return !!action.payload;
      default:
        return state;
    }
  },
});
