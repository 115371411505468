import { Observable } from "rxjs";
import { http } from "../../global/http/http";
import { Skin } from "./skin.constants";
import { DEBUG } from "../../environment";

function skinBackup$(): Observable<Skin | undefined> {
  return http.uglyGet$<Skin>(
    `${DEBUG ? "/form.json" : `/registration/form.json`}`
  );
}

export const skinService = {
  skinBackup$,
};
