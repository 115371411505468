export function isStrictPassword(pass: string): boolean {
  if (/^((?=.*[a-zA-Z])(?=.*[0-9]))[!@$&*a-zA-Z0-9-+]{6,}/.test(pass)) {
    return true;
  }
  return false;
}

export function isStrongPassword(pass: string): boolean {
  if (/^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))[!@$&a-zA-Z0-9-+]{6,}/.test(pass)) {
    return true;
  }
  return false;
}
