import { combineEpics } from "redux-observable";
import { filter, switchMap, map } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { catchHttpError } from "../../global/http/http";
import { EpicType } from "../store/store.types";
import { backupSkinFetchFail, backupSkinFetchSuccess } from "./skin.action";
import { SKIN_FETCH_BACKUP } from "./skin.constants";
import { skinService } from "./skin.service";

const skinBackup$: EpicType = (action$) =>
  action$.pipe(
    filter(isOfType(SKIN_FETCH_BACKUP)),
    switchMap(() =>
      skinService.skinBackup$().pipe(
        map((response) =>
          response ? backupSkinFetchSuccess(response) : backupSkinFetchFail()
        ),
        catchHttpError(backupSkinFetchFail)
      )
    )
  );

export const skinEpics = combineEpics(skinBackup$);
