/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

export const useOnMount = (callback: () => void) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      callback();
      setMounted(true);
    }
  });
};
export const useOnUnmount = (callback: () => void) => {
  useEffect(() => {
    return () => {
      callback();
    };
  }, []);
};
