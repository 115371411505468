import { action } from "typesafe-actions";
import {
  LANGUAGE_SET,
  Translation,
  TRANSLATIONS_FETCH_BACKUP,
  TRANSLATIONS_FETCH_BACKUP_FAIL,
  TRANSLATIONS_FETCH_BACKUP_SUCCESS,
  TRANSLATIONS_SET,
} from "./translations.constants";

export const fetchTranslationsBackup = () => action(TRANSLATIONS_FETCH_BACKUP);
export const fetchTranslationsBackupSuccess = (translations: Translation[]) =>
  action(TRANSLATIONS_FETCH_BACKUP_SUCCESS, translations);
export const fetchTranslationsBackupFail = () =>
  action(TRANSLATIONS_FETCH_BACKUP_FAIL);

export const translationsSet = (translations: Translation[] | undefined) =>
  action(TRANSLATIONS_SET, translations);
export const languageSet = (language: string) => action(LANGUAGE_SET, language);
