import React, { createContext } from "react";
import app from "firebase/app";
import "firebase/database";
import { useDispatch } from "react-redux";
import { remoteConfig } from "../../global/firebase/firebase";
import { skinSet } from "../../core/skin/skin.action";
import { myDomain } from "../../global/url-extractor/url-extractor";

const FirebaseContext = createContext<null | any>(null);
export { FirebaseContext };

export const FirebaseProvider = (props: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  //ocisti ovo kolko god da je moguce
  function getSkin() {
    const value = remoteConfig.getValue(myDomain());
    const skin = value.asString();
    if (skin) {
      dispatch(skinSet(JSON.parse(skin)));
    }
  }
  if (!app.apps.length) {
    app.initializeApp(remoteConfig);
  }

  return (
    <FirebaseContext.Provider value={{ getSkin: getSkin }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};
