import { Subject } from "rxjs";
import { RootAction } from "../store/store.types";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { filter, take, timeout } from "rxjs/operators";

const messages$ = new Subject<RootAction>();

export function emitActionMessage(action: RootAction) {
  messages$.next(action);
}

export function actionMessageOfType$(
  type: RootAction["type"] | RootAction["type"][]
): Observable<RootAction> {
  return messages$.pipe(filter(isOfType(type)));
}

export function actionMessageOfType(
  type: RootAction["type"] | RootAction["type"][],
  timeoutDuration = 10000
): Promise<RootAction> {
  return actionMessageOfType$(type)
    .pipe(take(1), timeout(timeoutDuration))
    .toPromise();
}
