import { action } from "typesafe-actions";
import { Environment } from "../websocket/websocket.constant";
import {
  ACCOUNT_CONNECT,
  ACCOUNT_CREATE,
  EMAIL_VERIFY,
  FormData,
  FORM_CLEAR,
  FORM_SET,
  FORM_UNIQUE_EMAIL_SET,
  LOCK_AGENT_CODE,
  PHONE_VERIFY,
  REQUEST_PHONE_VERIFICATION,
  REQUEST_PHONE_VERIFICATION_FAIL,
  REQUEST_PHONE_VERIFICATION_SUCCESS,
  SUBJECT_CLEAR,
  SUBMIT_PHONE_VERIFICATION,
  SUBMIT_PHONE_VERIFICATION_FAIL,
  SUBMIT_PHONE_VERIFICATION_SUCCESS,
  WEBSOCKET_CONNECT,
  WEBSOCKET_SET,
} from "./form.constants";

export const formSet = (form: FormData) => action(FORM_SET, form);
export const accountCreate = () => action(ACCOUNT_CREATE);
export const formUniqueEmailSet = (unique: boolean) =>
  action(FORM_UNIQUE_EMAIL_SET, unique);
export const formClear = () => action(FORM_CLEAR);

export const subjectClear = () => action(SUBJECT_CLEAR);
export const websocketConnect = (environment: Environment) =>
  action(WEBSOCKET_CONNECT, environment);
export const websocketSet = (data: any) => action(WEBSOCKET_SET, data);
export const accountConnect = () => action(ACCOUNT_CONNECT);

export const phoneVerify = (mustBeVerified: boolean) =>
  action(PHONE_VERIFY, mustBeVerified);
export const emailVerify = (mustBeVerified: boolean) =>
  action(EMAIL_VERIFY, mustBeVerified);

export const requestPhoneVerification = () =>
  action(REQUEST_PHONE_VERIFICATION);
export const requestPhoneVerificationSuccess = () =>
  action(REQUEST_PHONE_VERIFICATION_SUCCESS);
export const requestPhoneVerificationFail = (error: string) =>
  action(REQUEST_PHONE_VERIFICATION_FAIL, error);

export const submitPhoneVerification = () => action(SUBMIT_PHONE_VERIFICATION);
export const submitPhoneVerificationSuccess = () =>
  action(SUBMIT_PHONE_VERIFICATION_SUCCESS);
export const submitPhoneVerificationFail = (error: string) =>
  action(SUBMIT_PHONE_VERIFICATION_FAIL, error);

export const lockAgentCode = () => action(LOCK_AGENT_CODE);
