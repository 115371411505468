import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { RootAction, RootState } from "./store.types";
import { rootEpic } from "./store.epic";
import { createLogger } from "redux-logger";
import { messagesMiddleware } from "../message/message.middleware";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer } from "./store.reducer";
import { DEBUG } from "../../environment";

export function configureStore() {
  const logger = createLogger({
    level: {
      prevState: false,
      nextState: "log",
      action: "log",
    },
  });

  const whitelist: (keyof RootState)[] = ["form"];

  const persistedReducer = persistReducer(
    {
      key: "root-registration",
      storage,
      whitelist: whitelist as string[],
    },
    rootReducer
  );

  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    any
  >();
  const store = createStore(
    persistedReducer,
    applyMiddleware(
      epicMiddleware,
      ...(DEBUG ? [logger] : []),
      messagesMiddleware
    )
  );

  const persistor = persistStore(store);

  epicMiddleware.run(rootEpic);

  return { store, persistor };
}
