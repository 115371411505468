import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";
import { LANGUAGES } from "../../global/fallback/languages";
import { TRANSLATIONS } from "../../global/fallback/translations";
import * as actions from "./translations.action";
import {
  LANGUAGE_SET,
  Translation,
  TRANSLATIONS_FETCH_BACKUP_FAIL,
  TRANSLATIONS_FETCH_BACKUP_SUCCESS,
  TRANSLATIONS_SET,
} from "./translations.constants";

type TranslationsState = Readonly<{
  translations: Translation[];
  loadedTranslations: boolean;
  language: string;
}>;

type TranslationsAction = ActionType<typeof actions>;

const initialState: TranslationsState = {
  translations: TRANSLATIONS,
  loadedTranslations: false,
  language: LANGUAGES[0],
};

export const translationsReducer = combineReducers<
  TranslationsState,
  TranslationsAction
>({
  translations: (state = initialState.translations, action) => {
    switch (action.type) {
      case TRANSLATIONS_SET:
        return action.payload ?? state;
      case TRANSLATIONS_FETCH_BACKUP_SUCCESS:
        return action.payload;
      case TRANSLATIONS_FETCH_BACKUP_FAIL:
        return state;
      default:
        return state;
    }
  },
  loadedTranslations: (state = initialState.loadedTranslations, action) => {
    switch (action.type) {
      case TRANSLATIONS_SET:
        return !!action.payload;
      case TRANSLATIONS_FETCH_BACKUP_SUCCESS:
        return true;
      case TRANSLATIONS_FETCH_BACKUP_FAIL:
        return true;
      default:
        return state;
    }
  },
  language: (state = initialState.language, action) => {
    switch (action.type) {
      case LANGUAGE_SET:
        return action.payload;
      default:
        return state;
    }
  },
});
