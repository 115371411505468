import firebase from "firebase/app";
import "firebase/remote-config";
export const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCM5zLQFt-NU9zJ84DMVCGN1ADudEJNwAc",
  authDomain: "poker-registration.firebaseapp.com",
  projectId: "poker-registration",
  storageBucket: "poker-registration.appspot.com",
  messagingSenderId: "812399973661",
  appId: "1:812399973661:web:74f01b670653a31b80bdf4",
  measurementId: "G-BCT9F7KRGC",
});

export const remoteConfig = firebase.remoteConfig();
remoteConfig.defaultConfig = {};

remoteConfig.settings.minimumFetchIntervalMillis = 10;
