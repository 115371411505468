import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastProvider } from "react-toast-notifications";
import { useStoreState } from "../../core/store/store.hook";
import {
  changeFavicon,
  changeTitle,
} from "../../global/tab-elements/window-util";
import { Form } from "../form/form.container";
import { Step1 } from "../multistep-form/step1.container";
import { Step2 } from "../multistep-form/step2.container";
import { PhoneVerification } from "../phone-verification/phone-verification.container";
import { SkinProvider } from "../skin-provider/skin-provider.container";
import { Success } from "../success/success-page.container";

export const Home = (): JSX.Element => {
  const page = useStoreState((state) => state.navigation.page);
  const skin = useStoreState((state) => state.skin.skin);

  const { t } = useTranslation();
  const loaded = useStoreState(
    (state) => state.translations.loadedTranslations
  );

  useEffect(() => {
    skin.favicon
      ? changeFavicon(skin.favicon)
      : changeFavicon("/registration/favicon.ico");
    skin.title && loaded && changeTitle(t(skin.title));
  }, [loaded, skin, t]);

  return (
    <ToastProvider>
      <ThemeProvider
        theme={createMuiTheme({
          palette: skin.palette,
        })}
      >
        <SkinProvider>
          {page === "form" ? (
            <Form />
          ) : page === "success" ? (
            <Success />
          ) : page === "phoneVerification" ? (
            <PhoneVerification />
          ) : page === "step1" ? (
            <Step1 />
          ) : page === "step2" ? (
            <Step2 />
          ) : null}
        </SkinProvider>
      </ThemeProvider>
    </ToastProvider>
  );
};
