export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export class HttpError extends Error {
  name: string;
  statusCode: number;
  info?: string;
  url: string;
  method: HttpMethod;

  constructor({
    message,
    statusCode = 500,
    info,
    url,
    method,
  }: {
    message: string;
    statusCode?: number;
    info?: string;
    url: string;
    method: HttpMethod;
  }) {
    super(message);
    this.name = "HttpError";
    this.statusCode = statusCode;
    this.info = info;
    this.url = url;
    this.method = method;
  }
}
