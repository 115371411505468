export function isStrictUsername(user: string): boolean {
  if (/^[_a-zA-Z0-9]*$/.test(user)) {
    return true;
  }
  return false;
}

export function isUsernameBlacklisted(
  user: string,
  blacklist: string[]
): boolean {
  return !!blacklist.find((element) =>
    user.toLowerCase().includes(element.toLowerCase())
  );
}
