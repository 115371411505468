import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../core/store/store.hook";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { formSet } from "../../core/form/form.action";

// const isCloseToBottom = (
//   layoutHeight: number,
//   offsetY: number,
//   contentHeight: number
// ) => {
//   const paddingToBottom = 20;
//   return layoutHeight + offsetY >= contentHeight - paddingToBottom;
// };

export const TermsOfService = (): JSX.Element => {
  const { t } = useTranslation();

  const skin = useStoreState((state) => state.skin.skin);
  const formData = useStoreState((state) => state.form.formState.form);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(formSet({ ...formData, acceptedTos: checked }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, dispatch]);

  return (
    <div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: 5,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <Typography
          // onClick={() => (skin.termsOfService ? setOpen(!open) : undefined)}
          gutterBottom={true}
          style={{
            margin: 0,
            alignSelf: "center",
          }}
          color={"textPrimary"}
        >
          {skin.termsOfServiceURL &&
          skin.termsOfServiceLinkPart1 &&
          skin.termsOfServiceLinkPart2 ? (
            <Typography>
              {t(skin.termsOfServiceLinkPart1)}
              <Link
                href={skin.termsOfServiceURL}
                color={"inherit"}
                target={"_blank"}
                style={{
                  textDecorationLine: "underline",
                }}
              >
                {t(skin.termsOfServiceLinkPart2)}
              </Link>
            </Typography>
          ) : (
            t("termsOfService")
          )}
        </Typography>
        {skin.termsOfServiceCheckbox ? (
          <Checkbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            color={"primary"}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        ) : null}
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle
          disableTypography
          style={{
            color: skin.palette?.text?.primary,
            backgroundColor: skin.palette?.background?.default,
            padding: 0,
          }}
        >
          <IconButton
            style={{ float: "right" }}
            onClick={() => setOpen(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            justifyContent: "center",
            alignContent: "center",
            color: skin.palette?.text?.primary,
            backgroundColor: skin.palette?.background?.default,
          }}
        >
          <Typography
            variant={"h6"}
            color={"textPrimary"}
            style={{
              maxWidth: 300,
              maxHeight: 600,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 10,
            }}
          >
            {t(skin.termsOfService ?? "")}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "flex-end",
            backgroundColor: skin.palette?.background?.default,
          }}
        >
          <Button
            onClick={() => {
              setChecked(true);
              setOpen(false);
            }}
            variant={"contained"}
          >
            {t("accept")}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              setChecked(false);
            }}
            variant={"contained"}
          >
            {t("decline")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
