import { combineReducers } from "redux";
import { formReducer } from "../form/form.reducer";
import { navigationReducer } from "../navigation/navigation.reducer";
import { skinReducer } from "../skin/skin.reducer";
import { translationsReducer } from "../translations/translations.reducer";
import { validityReducer } from "../validity/validity.reducer";

export const rootReducer = combineReducers({
  skin: skinReducer,
  translations: translationsReducer,
  form: formReducer,
  navigation: navigationReducer,
  validity: validityReducer,
});
