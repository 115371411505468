import { Button, Divider, Grid, Typography, Box } from "@material-ui/core";
import React from "react";
import { useToasts } from "react-toast-notifications";

import { COUNTRY_ISO_CODES } from "../../global/fallback/iso-codes";
import {
  isStrictPassword,
  isStrongPassword,
} from "../../global/string-checks/strict-password";
import { isStrictString } from "../../global/string-checks/strict-string";
import "react-phone-input-2/lib/style.css";
import { format } from "date-fns";
import { isStrictNumber } from "../../global/string-checks/strict-number";
import { useTranslation } from "react-i18next";
import { Translations } from "../translations/translations.component";
import { visitUrl } from "../../global/http/external-linker";
import { useStoreState } from "../../core/store/store.hook";
import { myUrlParams } from "../../global/url-extractor/url-extractor";
import { useDispatch } from "react-redux";
import { formSet, websocketSet } from "../../core/form/form.action";
import { useOnMount } from "../../global/hooks/on-mount.hook";
import { WSProtocol } from "../../core/websocket/websocket.constant";
import { AutocompleteLocal } from "../autocomplete/autocomplete.component";
import { TermsOfService } from "../terms-of-service/terms-of-service.container";
import csc from "country-state-city";
import { Over18 } from "../over18/over18.container";
import { FormElement } from "../form/form-element.component";
import { ElementDate } from "../form/element-date.component";
import { FormDialog } from "../form/form-dialog.component";
import { FormSubmit } from "../form/form-submit.component";
import { validitySet } from "../../core/validity/validity.action";

export const Step2 = (): JSX.Element => {
  const { t } = useTranslation();
  const agentCode = myUrlParams().find((param) => param.key === "referral")
    ?.value;
  const { addToast } = useToasts();
  const skin = useStoreState((state) => state.skin.skin);
  const rootAgent = skin.rootAgent ?? skin.skin;
  const dispatch = useDispatch();
  const validity = useStoreState((state) => state.validity.validity);
  const formData = useStoreState((state) => state.form.formState.form);

  useOnMount(() => {
    dispatch(validitySet({ dialog: null }));
    dispatch(formSet({ ...formData, referralCode: agentCode }));
    if (formData.referralCode) {
      dispatch(
        websocketSet({
          type: WSProtocol.AccountValidateReferralCode,
          Str1: formData.referralCode,
        })
      );
    }
    if (formData.promoCode) {
      dispatch(
        websocketSet({
          type: WSProtocol.CheckPromoCode,
          Str1: formData.promoCode,
          Value: 1,
        })
      );
    }
  });

  return (
    <div
      style={{
        backgroundImage: "url(/registration/background.jpg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <Grid container>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
        <Grid
          item
          xl={2}
          lg={4}
          md={6}
          sm={8}
          xs={10}
          style={{
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor:
                skin.palette.background?.form || "rgba(1, 38, 63, 0.9)",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignSelf: "center",
                paddingTop: 10,
              }}
            >
              <img
                src={"/registration/logo.png"}
                alt="logo"
                style={{
                  height: 60,
                  objectFit: "contain",
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
              }}
            >
              <Box
                style={{
                  height: 30,
                  flex: 1,
                  marginBottom: 10,
                  marginLeft: 15,
                }}
              ></Box>
              <Typography
                variant="h5"
                color={"textPrimary"}
                gutterBottom
                style={{
                  flex: 5,
                  flexWrap: "wrap",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {t("title")}
              </Typography>
              <Translations
                containerStyle={{
                  marginBottom: 7,
                  marginTop: 3,
                  marginRight: 15,
                  flex: 1,
                  backgroundColor: "#00000033",
                }}
                variant={"outlined"}
              />
            </Box>
            {
              <FormElement
                label={t("password")}
                mandatory={true}
                placeholder={skin.passwordPlaceholder}
                onChange={(password) =>
                  dispatch(formSet({ ...formData, password }))
                }
                validateText={(str) =>
                  (skin.strongPass
                    ? isStrongPassword(str)
                    : isStrictPassword(str)) && str.length < 30
                }
                type={"password"}
                helperText={
                  skin.ommitedFields?.passwordHelperText
                    ? undefined
                    : t(
                        formData.password.length > 29
                          ? "passwordLongHelperText"
                          : formData.password === formData.username
                          ? "alertPasswordSameAsUsername"
                          : skin.strongPass
                          ? "strongPasswordHelperText"
                          : "passwordHelperText"
                      )
                }
              />
            }
            {
              <FormElement
                label={t("passwordRepeat")}
                mandatory={true}
                placeholder={skin.passwordRepeatPlaceholder}
                onChange={(repeatPassword) =>
                  dispatch(formSet({ ...formData, repeatPassword }))
                }
                strictText={(str) => str.length < 30}
                validateText={(str) => str === formData.password}
                type={"password"}
                helperText={
                  skin.ommitedFields?.passwordRepeatHelperText
                    ? undefined
                    : t("passwordRepeatHelperText")
                }
              />
            }
            {!skin.ommitedFields?.name && (
              <FormElement
                label={t("name")}
                mandatory={skin.mandatoryFields?.name}
                defaultValue={formData.name}
                placeholder={skin.namePlaceholder ?? ""}
                onChange={(name) => dispatch(formSet({ ...formData, name }))}
                strictText={(str) => isStrictString(str)}
                validateText={(str) => str.length < 20}
                strictAlert={
                  skin.ommitedFields?.nameAlert
                    ? () =>
                        addToast(t("strictName"), {
                          appearance: "warning",
                          autoDismiss: true,
                        })
                    : undefined
                }
                helperText={formData.name ? t("nameHelperText") : undefined}
              />
            )}
            {!skin.ommitedFields?.surname && (
              <FormElement
                label={t("surname")}
                mandatory={skin.mandatoryFields?.surname}
                defaultValue={formData.surname}
                placeholder={skin.surnamePlaceholder ?? ""}
                onChange={(surname) =>
                  dispatch(formSet({ ...formData, surname }))
                }
                strictText={(str) => isStrictString(str) && str.length < 20}
              />
            )}
            {!skin.ommitedFields?.birthDate && (
              <ElementDate
                label={`${t("birthDate")}${
                  skin.mandatoryFields?.birthDate ? "*" : ""
                }`}
                onChange={(birthDate) => {
                  dispatch(
                    formSet({
                      ...formData,
                      birthDate: format(birthDate, "yyyy-LL-dd"),
                    })
                  );
                }}
              />
            )}

            {!skin.ommitedFields?.countryCodes && (
              <AutocompleteLocal
                label={`${t("countryCodes")}${
                  skin.mandatoryFields?.countryIsoCode ? "*" : ""
                }`}
                list={COUNTRY_ISO_CODES}
                onSelect={(selection) =>
                  dispatch(
                    formSet({
                      ...formData,
                      countryIsoCode: selection?.code,
                    })
                  )
                }
                style={{
                  marginTop: 3,
                  marginBottom: 3,
                  marginLeft: 15,
                  marginRight: 15,
                  flex: 1,
                }}
                paperStyle={{
                  color: skin.palette.text?.primary,
                  backgroundColor: skin.palette.background?.alternative,
                }}
                defaultValue={formData.countryIsoCode}
              />
            )}

            {skin.stateLabel &&
              (skin.ommitedFields?.countryCodes || formData.countryIsoCode) &&
              (skin.strictStates ? (
                <AutocompleteLocal
                  label={`${t(skin.stateLabel)}${
                    skin.mandatoryFields?.state ? "*" : ""
                  }`}
                  list={
                    skin.ommitedFields?.countryCodes
                      ? csc
                          .getAllStates()
                          .map((state) => ({
                            code: state.isoCode,
                            value: state.name,
                          }))
                          .filter(
                            (state) =>
                              !skin.stateForbidden?.find(
                                (forbidden) => forbidden === state.value
                              )
                          )
                      : csc
                          //formData.countryIsoCode will allways be true if this condition is resolved
                          .getStatesOfCountry(formData.countryIsoCode!)
                          .map((state) => ({
                            code: state.isoCode,
                            value: state.name,
                          }))
                          .filter(
                            (state) =>
                              !skin.stateForbidden?.find(
                                (forbidden) => forbidden === state.value
                              )
                          )
                  }
                  onSelect={(selection) =>
                    dispatch(formSet({ ...formData, state: selection?.value }))
                  }
                  style={{
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 15,
                    marginRight: 15,
                    flex: 1,
                  }}
                  paperStyle={{
                    color: skin.palette.text?.primary,
                    backgroundColor: skin.palette.background?.alternative,
                  }}
                />
              ) : (
                <FormElement
                  label={t(skin.stateLabel)}
                  mandatory={skin.mandatoryFields?.state}
                  onChange={(code) =>
                    dispatch(formSet({ ...formData, state: code }))
                  }
                  palette={skin.palette}
                  defaultValue={formData.state || skin.stateDefault}
                  validateText={(str) =>
                    !skin.stateForbidden?.find(
                      (state) => state.toLowerCase() === str.toLowerCase()
                    )
                  }
                  helperText={
                    skin.stateHelperText ? t(skin.stateHelperText) : undefined
                  }
                />
              ))}
            {skin.cityLabel && (
              <FormElement
                label={t(skin.cityLabel)}
                mandatory={skin.mandatoryFields?.city}
                onChange={(city) => dispatch(formSet({ ...formData, city }))}
                placeholder={skin.cityPlaceholder}
                defaultValue={formData.city}
              />
            )}
            {skin.zipLabel && (
              <FormElement
                label={t(skin.zipLabel)}
                mandatory={skin.mandatoryFields?.zip}
                onChange={(zip) => dispatch(formSet({ ...formData, zip }))}
                placeholder={skin.zipPlaceholder}
                strictText={
                  skin.zipStrict ? (str) => isStrictNumber(str) : undefined
                }
                defaultValue={formData.zip}
              />
            )}
            {skin.addressLabel && (
              <FormElement
                label={t(skin.addressLabel)}
                mandatory={skin.mandatoryFields?.address}
                onChange={(address) =>
                  dispatch(formSet({ ...formData, address }))
                }
                placeholder={skin.addressPlaceholder}
                defaultValue={formData.address}
              />
            )}
            {skin.governmentIdLabel &&
              (skin.governmentIdDropdown ? (
                <AutocompleteLocal
                  label={`${t(skin.governmentIdLabel)}${
                    skin.mandatoryFields?.governmentId ? "*" : ""
                  }`}
                  list={skin.governmentIdDropdown.map((element) => ({
                    code: element,
                    value: element,
                  }))}
                  onSelect={(selection) =>
                    dispatch(
                      formSet({
                        ...formData,
                        governmentId: selection?.code,
                      })
                    )
                  }
                  style={{
                    marginTop: 3,
                    marginBottom: 3,
                    marginLeft: 15,
                    marginRight: 15,
                    flex: 1,
                  }}
                  paperStyle={{
                    color: skin.palette.text?.primary,
                    backgroundColor: skin.palette.background?.alternative,
                  }}
                  defaultValue={formData.governmentId}
                />
              ) : (
                <FormElement
                  label={t(skin.governmentIdLabel)}
                  mandatory={skin.mandatoryFields?.governmentId}
                  defaultValue={formData.governmentId}
                  onChange={(governmentId) =>
                    dispatch(formSet({ ...formData, governmentId }))
                  }
                  placeholder={skin.governmentIdPlaceholder}
                  strictText={
                    skin.governmentIdStrictNumber
                      ? (str) => isStrictNumber(str)
                      : undefined
                  }
                  validateText={(str) =>
                    (skin.governmentIdLimitTop
                      ? !(str.length > skin.governmentIdLimitTop)
                      : true) &&
                    (skin.governmentIdLimitLow
                      ? !(str.length < skin.governmentIdLimitLow)
                      : true)
                  }
                  helperText={
                    skin.governmentIdHelperText
                      ? t(skin.governmentIdHelperText)
                      : undefined
                  }
                  customHelperColor={skin.governmentIdCustomHelperTextColor}
                />
              ))}

            {!skin.ommitedFields?.referralCodeLabel && (
              <FormElement
                label={t("referralCodeLabel")}
                mandatory={skin.mandatoryFields?.referralCode}
                placeholder={skin.referralCodePlaceholder}
                onChange={(code) => {
                  if (code.length) {
                    dispatch(
                      websocketSet({
                        type: WSProtocol.AccountValidateReferralCode,
                        Str1: code,
                      })
                    );
                  }
                  dispatch(
                    formSet({
                      ...formData,
                      referralCode: code || rootAgent,
                    })
                  );
                }}
                defaultValue={agentCode}
                disabled={
                  skin.editableReferralCode === false ||
                  (!!agentCode && !skin.editableReferralCode)
                }
                helperText={t("referralCodeHelperText")}
                validateText={(str) => validity.referralCode || str === ""}
              />
            )}
            {!skin.ommitedFields?.promoCode && (
              <FormElement
                label={t("promoCodeLabel")}
                mandatory={skin.mandatoryFields?.promoCode}
                placeholder={skin.promoCodePlaceholder}
                onChange={(code) => {
                  if (code.length) {
                    dispatch(
                      websocketSet({
                        type: WSProtocol.CheckPromoCode,
                        Str1: code,
                        Value: 1,
                      })
                    );
                  }
                  dispatch(
                    formSet({
                      ...formData,
                      promoCode: code,
                    })
                  );
                }}
                defaultValue={formData.promoCode}
                helperText={t("promoCodeHelperText")}
                validateText={(str) => validity.promoCode || str === ""}
              />
            )}
            {(skin.termsOfService ||
              skin.termsOfServiceURL ||
              skin.termsOfServiceCheckbox) && <TermsOfService />}
            {skin.over18Checkbox ? <Over18 /> : null}

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Typography
                gutterBottom
                color={"textPrimary"}
                style={{ fontFamily: "arial" }}
              >
                {t("mandatoryLabel")}
              </Typography>
            </Box>

            <Divider />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 4,
              }}
            >
              {skin.cancelUrl ? (
                <Button
                  style={{
                    marginLeft: 5,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  variant={"outlined"}
                  onClick={() => {
                    skin.cancelUrl && visitUrl(skin.cancelUrl);
                  }}
                >
                  {t("cancel")}
                </Button>
              ) : null}
              <FormSubmit />
            </Box>
          </Box>
        </Grid>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
      </Grid>
      <FormDialog />
    </div>
  );
};
