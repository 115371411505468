import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { configureStore } from "./core/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Home } from "./common/home/home.container";
import { FirebaseProvider } from "./common/firebase-provider/firebase-provider.container";
import { useOnMount } from "./global/hooks/on-mount.hook";
import { visitUrl } from "./global/http/external-linker";
import { DEBUG } from "./environment";

export const NARROW = window.innerWidth < 700;

const App: React.FC = () => {
  const { store, persistor } = configureStore();

  useOnMount(() => {
    if (!DEBUG && window.location.protocol !== `https:`) {
      visitUrl(`https:${window.location.href.split(`:`)[1]}`);
    }
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FirebaseProvider>
          <Home />
        </FirebaseProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
