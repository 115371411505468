import { Observable } from "rxjs";
import { http } from "../../global/http/http";
import { FormData } from "../form/form.constants";

function requestCode$(form: FormData): Observable<any> {
  return http.get$(
    `/PhoneVerificationInitiate?username=${form.username}&email=${form.email}&phone=${form.phoneNumber}`
  );
}

function submitCode$(form: FormData): Observable<any> {
  return http.get$(
    `/PhoneVerificationVerify?username=${form.username}&email=${form.email}&phone=${form.phoneNumber}&code=${form.code}`
  );
}

export const formService = {
  requestCode$,
  submitCode$,
};
