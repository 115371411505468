export const TRANSLATIONS = [
  {
    language: "en",
    namespace: "translation",
    key: "username",
    value: "Username",
  },
  {
    language: "en",
    namespace: "translation",
    key: "usernameHelperText",
    value: "Username has to be between 5 and 20 characters long",
  },
  {
    language: "en",
    namespace: "translation",
    key: "usernameTaken",
    value: "Username is already in use",
  },
  {
    language: "en",
    namespace: "translation",
    key: "strictUsername",
    value: "Username can only contain latin characters and numbers",
  },
  {
    language: "en",
    namespace: "translation",
    key: "successTitle",
    value: "Congratulations! You have successfully created your account ",
  },
  {
    language: "en",
    namespace: "translation",
    key: "title",
    value: "Registration",
  },
  {
    language: "en",
    namespace: "translation",
    key: "email",
    value: "email",
  },
  {
    language: "en",
    namespace: "translation",
    key: "emailHelperText",
    value: "Please enter a valid email",
  },
  {
    language: "en",
    namespace: "translation",
    key: "emailPlaceholder",
    value: "poker.player@example.com",
  },
  {
    language: "en",
    namespace: "translation",
    key: "emailTaken",
    value: "Email address is already in use",
  },
  {
    language: "en",
    namespace: "translation",
    key: "password",
    value: "password",
  },
  {
    language: "en",
    namespace: "translation",
    key: "passwordHelperText",
    value:
      "Password has to be between 6 and 29 characters, has to include 1 letter and 1 number",
  },
  {
    language: "en",
    namespace: "translation",
    key: "passwordRepeat",
    value: "Repeat Password",
  },
  {
    language: "en",
    namespace: "translation",
    key: "passwordRepeatHelperText",
    value: "Passwords do not match",
  },
  {
    language: "en",
    namespace: "translation",
    key: "name",
    value: "Name",
  },
  {
    language: "en",
    namespace: "translation",
    key: "surname",
    value: "Surname",
  },
  {
    language: "en",
    namespace: "translation",
    key: "phone",
    value: "Phone number",
  },
  {
    language: "en",
    namespace: "translation",
    key: "birthDate",
    value: "Birth Date",
  },
  {
    language: "en",
    namespace: "translation",
    key: "countryCodes",
    value: "Country",
  },
  {
    language: "en",
    namespace: "translation",
    key: "state",
    value: "State",
  },
  {
    language: "en",
    namespace: "translation",
    key: "city",
    value: "City",
  },
  {
    language: "en",
    namespace: "translation",
    key: "zipCode",
    value: "Zip Code",
  },
  {
    language: "en",
    namespace: "translation",
    key: "referralCodeLabel",
    value: "Referral Code",
  },
  {
    language: "en",
    namespace: "translation",
    key: "referralCodeHelperText",
    value: "Invalid referral code",
  },
  {
    language: "en",
    namespace: "translation",
    key: "mandatoryLabel",
    value: "Please fill all fields marked with: *",
  },
  {
    language: "en",
    namespace: "translation",
    key: "cancel",
    value: "Cancel",
  },
  {
    language: "en",
    namespace: "translation",
    key: "submit",
    value: "Submit",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertMissingPhone",
    value: "Please enter a phone number",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertTakenPhone",
    value: "Phone number already in use",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertMandatoryFields",
    value: "Please fill all fields marked with *",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertEmailInvalid",
    value: "Please enter a valid email address",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertEmailTaken",
    value: "Email address is already in use",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertUsernameShort",
    value: "Username has to be at least 5 characters long",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertUsernameTaken",
    value: "Username is already in use",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertInvalidPassword",
    value:
      "Password has to be between 6 and 29 characters in legth, has to contain at least 1 number and 1 letter. Allowed characters are: a-z, A-Z, !, @, $, &, *, -, + ",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertPasswordRepeat",
    value: "Passwords do not match!",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertInvalidBirthDate",
    value: "Please enter a valid date",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertYoungBirthDate",
    value: "You are too young to play!",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertMissingDate",
    value: "Please enter your birth date",
  },
  {
    language: "en",
    namespace: "translation",
    key: "alertInvalidReferralCode",
    value: "Invalid referral code",
  },
  {
    language: "en",
    namespace: "translation",
    key: "oops",
    value:
      "Something went wrong.\nCheck your internet connection and try again.",
  },
  {
    language: "en",
    namespace: "translation",
    key: "instantPlay",
    value: "Instant Play",
  },
  {
    language: "en",
    namespace: "translation",
    key: "instantPlayCaption",
    value: "Play from your browser\nNo Download required",
  },
  {
    language: "en",
    namespace: "translation",
    key: "download",
    value: "Download",
  },
  {
    language: "en",
    namespace: "translation",
    key: "downloadCaption",
    value: "Download for your operating system",
  },
  {
    language: "en",
    namespace: "translation",
    key: "redirectionAccept",
    value: "Accept",
  },
  {
    language: "en",
    namespace: "translation",
    key: "redirectionDecline",
    value: "Decline",
  },

  {
    language: "rs",
    namespace: "translation",
    key: "username",
    value: "Cool ",
  },
  {
    language: "rs",
    namespace: "translation",
    key: "usernameHelperText",
    value: "Cool ",
  },
  {
    language: "rs",
    namespace: "translation",
    key: "title",
    value: "Registration Page",
  },
  {
    language: "rs",
    namespace: "translation",
    key: "successTitle",
    value: "Svaka vam dala ",
  },
];
