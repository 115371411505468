import { action } from "typesafe-actions";
import { Skin, SKIN_SET } from "./skin.constants";
import {
  SKIN_FETCH_BACKUP,
  SKIN_FETCH_BACKUP_FAIL,
  SKIN_FETCH_BACKUP_SUCCESS,
} from "./skin.constants";

export const backupSkinFetch = () => action(SKIN_FETCH_BACKUP);
export const backupSkinFetchSuccess = (skin: Skin) =>
  action(SKIN_FETCH_BACKUP_SUCCESS, skin);
export const backupSkinFetchFail = () => action(SKIN_FETCH_BACKUP_FAIL);

export const skinSet = (skin: Skin | undefined) => action(SKIN_SET, skin);
