import { combineEpics } from "redux-observable";
import { filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { catchHttpError } from "../../global/http/http";
import { navigationPageSet } from "../navigation/navigation.action";
import { EpicType } from "../store/store.types";
import {
  requestPhoneVerificationFail,
  requestPhoneVerificationSuccess,
  submitPhoneVerificationFail,
} from "./form.action";
import {
  REQUEST_PHONE_VERIFICATION,
  SUBMIT_PHONE_VERIFICATION,
} from "./form.constants";
import { formService } from "./form.service";

const requestPhoneVerification$: EpicType = (action$, state$) =>
  action$.pipe(
    filter(isOfType(REQUEST_PHONE_VERIFICATION)),
    withLatestFrom(state$.pipe(map((state) => state.form.formState.form))),
    switchMap(([, form]) => {
      return formService.requestCode$(form).pipe(
        map((response) => {
          return response.Status === "success"
            ? requestPhoneVerificationSuccess()
            : requestPhoneVerificationFail("");
        }),
        catchHttpError(() => requestPhoneVerificationFail(""))
      );
    })
  );

const submitPhoneVerification$: EpicType = (action$, state$) =>
  action$.pipe(
    filter(isOfType(SUBMIT_PHONE_VERIFICATION)),
    withLatestFrom(state$.pipe(map((state) => state.form.formState.form))),
    switchMap(([, form]) =>
      formService.submitCode$(form).pipe(
        map((response) =>
          response.Status === "success"
            ? navigationPageSet("step2")
            : submitPhoneVerificationFail("")
        ),
        catchHttpError(() => submitPhoneVerificationFail(""))
      )
    )
  );

export const formEpics = combineEpics(
  requestPhoneVerification$,
  submitPhoneVerification$
);
