import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { useOnMount } from "../../global/hooks/on-mount.hook";
import { isAndroid, isIOS, isMacOs, isWindows } from "react-device-detect";
import { Downloads } from "../../core/skin/skin.constants";
import { visitUrl } from "../../global/http/external-linker";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../core/store/store.hook";
import { NARROW } from "../../App";
import { myUrlParams } from "../../global/url-extractor/url-extractor";
import { useDispatch } from "react-redux";
import { emailVerify, formClear } from "../../core/form/form.action";
import ReactGA from "react-ga";

let OS: keyof Downloads;

interface ButtonPalette {
  background?: string;
  text?: string;
  image?: string;
  variant?: "outlined" | "contained";
}

export const Success = (): JSX.Element => {
  const skin = useStoreState((state) => state.skin.skin);
  const form = useStoreState((state) => state.form.formState.form);
  const emailVerification = useStoreState(
    (state) => state.form.formState.emailMustBeVerified
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [username] = useState<string>(form.username);
  const [email] = useState<string>(form.email);
  const [showManual, setShowManual] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [instantPlay, setInstantPlay] = useState<ButtonPalette>({
    background: skin.palette.background?.default,
    text: skin.palette.text?.primary,
    image: undefined,
    variant: "outlined",
  });
  const [download, setDownload] = useState<ButtonPalette>({
    background: skin.palette.background?.default,
    text: skin.palette.text?.primary,
    image: undefined,
    variant: "outlined",
  });

  const fromApp =
    myUrlParams().find((param) => param.key === "app")?.value === "true";

  useOnMount(async () => {
    dispatch(formClear());
    if (skin.trackingIdGA) {
      ReactGA.event({
        category: "User",
        action: "Created an Account",
        label: window.location.href,
      });
    }
    if (skin.callbackUrl) {
      setTimeout(() => {
        visitUrl(skin.callbackUrl!);
      }, 10000);
      return;
    }
    switch (true) {
      case isAndroid:
        OS = "Android";
        break;
      case isIOS:
        OS = "IOS";
        break;
      case isWindows:
        OS = "Windows";
        break;
      case isMacOs:
        OS = "Mac";
        break;
      default:
        break;
    }
    if (!skin.downloads?.[OS] && skin.instantPlayUrl) {
      setTimeout(() => {
        visitUrl(skin.instantPlayUrl!);
      }, 10000);
      return;
    }
  });

  return (
    <div
      style={{
        backgroundImage: "url(/registration/background.jpg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <Grid container>
        <Grid item xl={4} lg={3} md={2} sm={1} xs={1} />
        <Grid item xl={4} lg={6} md={8} sm={10} xs={10}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Box style={{ flex: 1 }} />
            <Box
              style={{
                flexGrow: 1,
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              {
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <img
                    src={"/registration/logo.png"}
                    alt="logo"
                    style={{ maxHeight: 150 }}
                  />
                </div>
              }
              <Typography
                color={"textPrimary"}
                style={{
                  flexGrow: 1,
                  margin: 20,
                  display: "block",
                }}
                variant="h4"
                gutterBottom
              >
                {`${t("successTitle").replace("[username]", username)}`}
              </Typography>
              {/* {skin.emailVerification ? (
                <Typography
                  color={"textPrimary"}
                  style={{
                    flexGrow: 1,
                    margin: 20,
                    display: "block",
                  }}
                  variant="h4"
                  gutterBottom
                >
                  {`${t("emailVerified").replace("[email]", email)}`}
                </Typography>
              ) : null} */}
              <Divider
                style={{
                  height: 2,
                  backgroundImage: skin.palette.gradient?.primary,
                  maxWidth: window.innerWidth - 50,
                  justifyContent: "center",
                }}
              />
              <Typography
                variant="h6"
                color={"textPrimary"}
                gutterBottom
                style={{ marginTop: 15 }}
              >
                {skin.successDescription && t(skin.successDescription)}
              </Typography>
              {!fromApp ? (
                <Box
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: NARROW ? "column" : "row",
                    marginLeft: NARROW ? 0 : 100,
                    marginRight: NARROW ? 0 : 100,
                    marginBottom: 10,
                    marginTop: 20,
                  }}
                >
                  {skin.instantPlayUrl && (
                    <Box
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        marginRight: skin.downloads?.[OS] && !NARROW ? 20 : 0,
                        marginBottom: 20,
                      }}
                    >
                      <a
                        href={
                          skin.redirectionDialogTitle
                            ? undefined
                            : skin.instantPlayUrl
                        }
                        style={{ flex: 1, textDecoration: "none" }}
                      >
                        <Button
                          variant={instantPlay.variant}
                          fullWidth
                          style={{
                            backgroundColor: instantPlay.background,
                            color: instantPlay.text,
                            lineHeight: 3,
                            width: 220,
                            whiteSpace: "nowrap",
                            backgroundImage: instantPlay.image,
                          }}
                          onMouseEnter={() => {
                            setInstantPlay({
                              background: undefined,
                              text: skin.palette.text?.secondary,
                              image: skin.palette.gradient?.primary,
                              variant: "contained",
                            });
                          }}
                          onMouseLeave={() => {
                            setInstantPlay({
                              background: skin.palette.background?.default,
                              text: skin.palette.text?.primary,
                              image: undefined,
                              variant: "outlined",
                            });
                          }}
                          onClick={() =>
                            skin.redirectionDialogTitle && setShowDialog(true)
                          }
                        >
                          {t("instantPlay")}
                        </Button>
                      </a>
                      <Typography
                        color={"textPrimary"}
                        gutterBottom
                        variant={"caption"}
                        style={{ marginTop: 10 }}
                      >
                        {t("instantPlayCaption")}
                      </Typography>
                    </Box>
                  )}
                  {skin.downloads?.[OS] && (
                    <Box
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: NARROW ? 0 : 20,
                        marginBottom: 20,
                      }}
                    >
                      <a
                        href={skin.downloads?.[OS]}
                        download
                        onClick={() => {
                          if (OS === "IOS") {
                            setShowManual(true);
                          }
                        }}
                        style={{ flex: 1, textDecoration: "none" }}
                      >
                        <Button
                          variant={download.variant}
                          fullWidth
                          style={{
                            backgroundColor: download.background,
                            color: download.text,
                            lineHeight: 3,
                            width: 220,
                            whiteSpace: "nowrap",
                            backgroundImage: download.image,
                          }}
                          onMouseEnter={() => {
                            setDownload({
                              background: undefined,
                              text: skin.palette.text?.secondary,
                              image: skin.palette.gradient?.primary,
                              variant: "contained",
                            });
                          }}
                          onMouseLeave={() => {
                            setDownload({
                              background: skin.palette.background?.default,
                              text: skin.palette.text?.primary,
                              image: undefined,
                              variant: "outlined",
                            });
                          }}
                        >{`${t("download").replace("[OS]", OS)}`}</Button>
                      </a>
                      <Typography
                        color={"textPrimary"}
                        gutterBottom
                        variant={"caption"}
                        style={{
                          marginTop: 10,
                        }}
                      >
                        {t("downloadCaption")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <Typography
                  color={"textPrimary"}
                  gutterBottom
                  variant={"h4"}
                  style={{
                    marginTop: 50,
                  }}
                >
                  {t("successFinished")}
                </Typography>
              )}
              {skin.callbackUrl && (
                <Box>
                  <Typography
                    color={"textPrimary"}
                    gutterBottom
                    variant={"h4"}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    {skin.callbackText ? t(skin.callbackText) : ""}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box style={{ flex: 1 }} />
          </Box>
          <Dialog
            open={showManual}
            onClose={() => setShowManual(!skin.dismissableManual)}
          >
            <DialogContent
              style={{ padding: 0, height: 200, overflow: "hidden" }}
            >
              <img src={skin.iosManualUrl} style={{ height: 200 }}></img>
            </DialogContent>
          </Dialog>
          <Dialog
            open={emailVerification}
            onClose={() => dispatch(emailVerify(false))}
          >
            <DialogTitle
              disableTypography
              style={{
                color: skin.palette?.text?.primary,
                backgroundColor: skin.palette.background?.default,
                borderBottomWidth: 1,
                borderBottomColor: "black",
                padding: 0,
              }}
            >
              <IconButton
                style={{ float: "right" }}
                onClick={() => dispatch(emailVerify(false))}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              style={{
                justifyContent: "center",
                alignContent: "center",
                color: skin.palette?.text?.primary,
                backgroundColor: skin.palette.background?.default,
              }}
            >
              <Typography
                variant={"h6"}
                color={"textPrimary"}
                style={{
                  maxWidth: 300,
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 10,
                  textAlign: "center",
                  alignSelf: "center",
                }}
              >
                <p>{`${t("emailVerifiedPart1").replace("[email]", email)}`}</p>
                <p style={{ backgroundColor: "#1464b5" }}>{`${t(
                  "emailVerifiedPart2"
                )}`}</p>
              </Typography>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: "flex-end",
                backgroundColor: skin.palette.background?.default,
              }}
            >
              <Button
                onClick={() => setShowDialog(false)}
                variant={"contained"}
              >
                {t("redirectionDecline")}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle
              disableTypography
              style={{
                color: skin.palette?.text?.primary,
                backgroundImage: skin.palette?.gradient?.secondary,
                padding: 0,
              }}
            >
              <IconButton
                style={{ float: "right" }}
                onClick={() => setShowDialog(false)}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              style={{
                justifyContent: "center",
                alignContent: "center",
                color: skin.palette?.text?.primary,
                backgroundImage: skin.palette?.gradient?.secondary,
              }}
            >
              <Typography
                variant={"h6"}
                color={"textPrimary"}
                style={{
                  maxWidth: 300,
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: 10,
                  textAlign: "center",
                  alignSelf: "center",
                }}
              >
                {t(skin.redirectionDialogTitle ?? "")}
              </Typography>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: "flex-end",
                backgroundColor: skin.palette.background?.default,
              }}
            >
              <Button
                onClick={() => dispatch(emailVerify(false))}
                variant={"contained"}
              >
                {t("ok")}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xl={4} lg={3} md={2} sm={1} xs={1}></Grid>
      </Grid>
    </div>
  );
};
