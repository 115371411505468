import {
  Button,
  createStyles,
  InputAdornment,
  TextField,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isStrictPhoneNumber } from "../../global/string-checks/strict-number";

export const StyledTextField = withStyles(() =>
  createStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#FFFFFF55",
        },
      },
      "& .MuiInputLabel-root": {
        color: "#AAAAAAAA",
        "&.Mui-focused": {
          color: "white",
        },
      },
      "& .MuiIconButton-root": {
        color: "white",
      },
    },
  })
)(TextField);

export const PhoneWithVerificationField = ({
  label,
  placeholder,
  onChange,
  onClick,
  defaultValue,
  disabled,
  mandatory,
}: {
  label: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onClick: () => void;
  defaultValue?: string;
  disabled?: boolean;
  mandatory?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(defaultValue || "");
  const [selected, setSelected] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 15,
        marginRight: 15,
      }}
      onFocus={() => selected || setSelected(true)}
      onBlur={() => selected && setSelected(false)}
      onMouseEnter={() => hovering || setHovering(true)}
      onMouseLeave={() => hovering && setHovering(false)}
    >
      <div style={{ flex: 5 }}>
        <StyledTextField
          label={`${label}${mandatory ? "*" : ""}`}
          placeholder={placeholder}
          variant="outlined"
          multiline={false}
          fullWidth
          onChange={(event) => {
            if (isStrictPhoneNumber(event.target.value)) {
              setValue(event.target.value);
              onChange(event.target.value);
              return;
            }
          }}
          disabled={disabled}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="outlined"
                  style={{}}
                  onClick={() => onClick()}
                  color={"secondary"}
                >
                  {t("sendOTP")}
                </Button>
              </InputAdornment>
            ),
          }}
        ></StyledTextField>
      </div>
    </div>
  );
};
