import { Skin } from "../../core/skin/skin.constants";

//napisi dokumentaciju

export const FORM: Skin = {
  skin: "RunPokerRun",
  title: "title",
  languages: ["en"],
  cancelUrl: "https://runpokerrun.com",
  phoneDefaultCode: "il",
  ommitedFields: {
    countryCodes: true,
    birthDate: true,
  },
  mandatoryFields: {
    name: true,
    surname: true,
    phoneNumber: true,
  },
  palette: {
    primary: {
      main: "#ffffffaa",
    },
    secondary: {
      main: "#f44336",
    },
    error: {
      main: "#b71c1c",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff77",
      disabled: "#0000003a",
    },
    background: {
      default: "#0000003a",
      alternative: "#f4433677",
    },
    gradient: {
      primary: "linear-gradient(to right, #73592f, #9e8864)",
      secondary: "linear-gradient(to right, #00000077, #0000003a)",
    },
  },
  successDescription: "runPokerRunSuccessDescription",
  downloads: {
    IOS:
      "itms-services://?action=download-manifest&url=https://www.pokerhouse.host/dl/iOS/new/manifest.plist",
    Android: "https://pokerhouse.host/dl/Android/PokerHouse.apk",
    Windows: "https://game.pokerhouse.host/Setup_PokerHouse.exe",
  },
};
