function isString(value: any) {
  return {}.toString.call(value) === "[object String]";
}

function isSerializedDate(value: any) {
  const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return isString(value) && datePattern.test(value);
}

export function dateReviver(_key: string, value: any) {
  if (isSerializedDate(value)) {
    return new Date(value);
  }
  return value;
}
