export const TRANSLATIONS_FETCH_BACKUP =
  "translationts/TRANSLATIONS_FETCH_BACKUP";
export const TRANSLATIONS_FETCH_BACKUP_SUCCESS =
  "translations/TRANSLATIONS_FETCH_BACKUP_SUCCESS";
export const TRANSLATIONS_FETCH_BACKUP_FAIL =
  "translations/TRANSLATIONS_FETCH_BACKUP_FAIL";

export const TRANSLATIONS_SET = "translations/TRANSLATIONS_SET";
export const LANGUAGE_SET = "translations/LANGUAGE_SET";

export interface Translation {
  language: string;
  namespace: string;
  key: string;
  value: string;
}
