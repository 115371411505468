import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";
import { Validity, VALIDITY_SET } from "./validity.constants";
import * as actions from "./validity.action";

type ValidityState = Readonly<{
  validity: Validity;
}>;

type ValidityAction = ActionType<typeof actions>;

const initialState: ValidityState = {
  validity: {
    phoneTaken: false,
    usernameTaken: false,
    referralCode: true,
    promoCode: true,
    dialog: null,
  },
};

export const validityReducer = combineReducers<ValidityState, ValidityAction>({
  validity: (state = initialState.validity, action) => {
    switch (action.type) {
      case VALIDITY_SET: {
        return { ...state, ...action.payload };
      }
      default:
        return state;
    }
  },
});
