import { DEBUG } from "../../environment";

export function myUrlNoParams(): string {
  return `${window.location.hostname}`;
}

export function myUrlParams(): { key: string; value: string }[] {
  const strings = window.location.href
    .substring(myUrlNoParams().length - 1, window.location.href.length)
    .split("?")[1]
    ?.split("&");
  const params =
    strings &&
    strings.map((str: string) => {
      const keyval = str.split("=");
      return { key: keyval[0], value: keyval[1] };
    });
  const debugStrings = window.location.href
    .split("/")[3]
    .split("?")[1]
    ?.split("&");
  const debugParams =
    debugStrings &&
    debugStrings.map((str: string) => {
      const keyval = str.split("=");
      return { key: keyval[0], value: keyval[1] };
    });

  return DEBUG ? debugParams ?? [] : params ?? [];
}

export function myDomain(): string {
  const parts = window.location.hostname.split(".");
  return parts[0] === `www` ? parts[1] : parts[0];
}
