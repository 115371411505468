export function isStrictString(str: string): boolean {
  for (const char of str) {
    if (
      char.toUpperCase() == char.toLowerCase() ||
      (char.codePointAt(0) && char.codePointAt(0)! > 127)
    ) {
      return false;
    }
  }

  return true;
}
