import { Observable } from "rxjs";
import { DEBUG } from "../../environment";
import { http } from "../../global/http/http";
import { Translation } from "./translations.constants";

function translationsBackup$(): Observable<Translation[] | undefined> {
  return http.uglyGet$<Translation[]>(
    `${DEBUG ? "/translations.json" : `/registration/translations.json`}`
  );
}

function languageListBackup$(): Observable<string[] | undefined> {
  return http.uglyGet$<string[]>(
    `${DEBUG ? "/languages.json" : `/registration/languages.json`}`
  );
}

export const translationsService = {
  translationsBackup$,
  languageListBackup$,
};
