import { combineEpics } from "redux-observable";
import { filter, switchMap, map } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { catchHttpError } from "../../global/http/http";
import { EpicType } from "../store/store.types";
import {
  fetchTranslationsBackupFail,
  fetchTranslationsBackupSuccess,
} from "./translations.action";
import { TRANSLATIONS_FETCH_BACKUP } from "./translations.constants";
import { translationsService } from "./translations.service";

const translationsBackup$: EpicType = (action$) =>
  action$.pipe(
    filter(isOfType(TRANSLATIONS_FETCH_BACKUP)),
    switchMap(() =>
      translationsService.translationsBackup$().pipe(
        map((response) =>
          response
            ? fetchTranslationsBackupSuccess(response)
            : fetchTranslationsBackupFail()
        ),
        catchHttpError(fetchTranslationsBackupFail)
      )
    )
  );

export const translationsEpics = combineEpics(translationsBackup$);
