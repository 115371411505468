import { Box, Checkbox, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../core/store/store.hook";
import { useDispatch } from "react-redux";
import { formSet } from "../../core/form/form.action";

export const Over18 = (): JSX.Element => {
  const { t } = useTranslation();

  const formData = useStoreState((state) => state.form.formState.form);

  const dispatch = useDispatch();

  return (
    <div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 5,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <Checkbox
          checked={!!formData.is18}
          onChange={() =>
            dispatch(formSet({ ...formData, is18: !formData.is18 }))
          }
          color={"primary"}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <Typography
          gutterBottom={true}
          style={{
            margin: 0,
            alignSelf: "center",
          }}
          color={"textPrimary"}
        >
          {t("over18")}
        </Typography>
      </Box>
    </div>
  );
};
