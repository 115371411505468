import { MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useStoreState } from "../../core/store/store.hook";
import { languageSet } from "../../core/translations/translations.action";
import { myUrlParams } from "../../global/url-extractor/url-extractor";

export const Translations = (props: {
  containerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  variant?: "standard" | "filled" | "outlined";
}) => {
  const dispatch = useDispatch();
  const agentCode = myUrlParams().find((param) => param.key === "referral")
    ?.value;
  const defaultLanguage = useStoreState(
    (state) =>
      state.skin.skin.subskinOptions?.find(
        (option) => option.skin === agentCode
      )?.languageDefault || state.skin.skin.languages[0]
  );
  const languages = useStoreState((state) => state.skin.skin.languages);
  const [lang, setLang] = useState(defaultLanguage);

  //ovo je mozda suvisno, proveri
  useEffect(() => {
    dispatch(languageSet(defaultLanguage));
  }, [defaultLanguage, dispatch]);

  if (languages.length === 1) {
    return (
      <div style={{ ...props.containerStyle, backgroundColor: undefined }} />
    );
  }

  return (
    <Select
      variant={props.variant}
      value={lang}
      style={{
        height: 30,
        backgroundColor: "#00000000",
        ...props.containerStyle,
      }}
      onChange={(event) => {
        setLang(event.target.value as string);
        if (event.target.value) {
          dispatch(languageSet(event.target.value as string));
        }
      }}
    >
      {languages.map((language, index) => (
        <MenuItem
          key={index}
          value={language}
          style={{
            backgroundColor: "white",
            color: "black",
            ...props.contentStyle,
          }}
          divider={!(index === languages.length - 1)}
        >
          {language}
        </MenuItem>
      ))}
    </Select>
  );
};
