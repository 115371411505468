import { Button, Divider, Grid, Typography, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { isStrictEmail } from "../../global/string-checks/strict-email";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { Translations } from "../translations/translations.component";
import { visitUrl } from "../../global/http/external-linker";
import { useStoreState } from "../../core/store/store.hook";
import { useDispatch } from "react-redux";
import { formSet, websocketSet } from "../../core/form/form.action";
import { isStrictUsername } from "../../global/string-checks/strict-username";
import { useOnMount } from "../../global/hooks/on-mount.hook";
import { WSProtocol } from "../../core/websocket/websocket.constant";
import { TermsOfService } from "../terms-of-service/terms-of-service.container";
import { validitySet } from "../../core/validity/validity.action";
import { Over18 } from "../over18/over18.container";
import { browserName, browserVersion } from "react-device-detect";
import { v4 } from "uuid";
import { FormElement } from "../form/form-element.component";
import { NextStep } from "./next-step.container";
import { FormDialog } from "../form/form-dialog.component";
import { PhoneWithVerificationField } from "./phone-with-verification-field.container";

export const Step1 = (): JSX.Element => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const skin = useStoreState((state) => state.skin.skin);
  const dispatch = useDispatch();
  const [noEmail, setNoEmail] = useState<boolean>(true);
  const validity = useStoreState((state) => state.validity.validity);
  const formData = useStoreState((state) => state.form.formState.form);
  const emailTaken = !useStoreState(
    (state) => state.form.formState.uniqueEmail
  );
  const phoneVerificationRequested = useStoreState(
    (state) => state.form.formState.phoneVerificationRequested
  );

  useOnMount(() => {
    dispatch(
      websocketSet({
        type: WSProtocol.Version,
        Str1: "Registration", //name
        Str2: skin.rootAgent || skin.skin, //skin / referral
        Str3: 1, //version
        Str4: `${browserName} ${browserVersion}`, //OS
        Str5: v4(), //guid
        Value2: 2, //platform
      })
    );
    // setInterval(() => {
    //   dispatch(websocketSet({ type: WSProtocol.Ping, Value2: 1 }));
    // }, 5000);
  });

  return (
    <div
      style={{
        backgroundImage: "url(/registration/background.jpg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <Grid container>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
        <Grid
          item
          xl={2}
          lg={4}
          md={6}
          sm={8}
          xs={10}
          style={{
            marginTop: 30,
            marginBottom: 20,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor:
                skin.palette.background?.form || "rgba(1, 38, 63, 0.9)",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignSelf: "center",
                paddingTop: 10,
              }}
            >
              <img
                src={"/registration/logo.png"}
                alt="logo"
                style={{
                  height: 60,
                  objectFit: "contain",
                }}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: 10,
              }}
            >
              <Box
                style={{
                  height: 30,
                  flex: 1,
                  marginBottom: 10,
                  marginLeft: 15,
                }}
              ></Box>
              <Typography
                variant="h5"
                color={"textPrimary"}
                gutterBottom
                style={{
                  flex: 5,
                  flexWrap: "wrap",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {t("title")}
              </Typography>
              <Translations
                containerStyle={{
                  marginBottom: 7,
                  marginTop: 3,
                  marginRight: 15,
                  flex: 1,
                  backgroundColor: "#00000033",
                }}
                variant={"outlined"}
              />
            </Box>

            <FormElement
              label={t(`username`)}
              mandatory={true}
              defaultValue={formData.username}
              placeholder={skin.usernamePlaceholder}
              onChange={(username) => {
                if (username.length > 4 && username.length < 21) {
                  dispatch(
                    websocketSet({
                      type: WSProtocol.AccountVerifyUsernameUnique,
                      Str1: username,
                    })
                  );
                }
                if (username.length < 5) {
                  dispatch(validitySet({ usernameTaken: false }));
                }
                dispatch(
                  formSet({
                    ...formData,
                    username,
                    email:
                      skin.ommitedFields?.email ||
                      (noEmail && !skin.mandatoryFields?.email)
                        ? `${username}@example.com`
                        : formData.email,
                  })
                );
                if (
                  skin.ommitedFields?.email ||
                  (noEmail && skin.mandatoryFields?.email)
                ) {
                  dispatch(
                    websocketSet({
                      Type: WSProtocol.AccountVerifyEmailUnique,
                      Str1: `${username}@example.com`,
                    })
                  );
                }
                //proveri da li je ovo uzrok bug-a
                if (username.length > 20) {
                  dispatch(validitySet({ usernameTaken: false }));
                }
              }}
              validateText={(str) =>
                str.length > 4 && !validity.usernameTaken && str.length < 13
              }
              strictText={(str) => isStrictUsername(str)}
              helperText={
                skin.ommitedFields?.usernameHelperText
                  ? undefined
                  : t(
                      validity.usernameTaken
                        ? "usernameTaken"
                        : formData.username.length < 5 &&
                          formData.username !== ""
                        ? "usernameShortHelperText"
                        : formData.username.length > 12
                        ? "usernameLongHelperText"
                        : "usernameHelperText"
                    )
              }
              strictAlert={
                !skin.ommitedFields?.usernameAlert
                  ? () =>
                      addToast(t("strictUsername"), {
                        appearance: "warning",
                        autoDismiss: true,
                      })
                  : undefined
              }
            />

            {!skin.ommitedFields?.email && (
              <FormElement
                label={t("email")}
                mandatory={skin.mandatoryFields?.email}
                defaultValue={
                  formData.email.search("@example.com") === -1
                    ? formData.email
                    : ""
                }
                placeholder={skin.emailPlaceholder && t(skin.emailPlaceholder)}
                onChange={(email) => {
                  if (email.length > 4 && isStrictEmail(email)) {
                    dispatch(
                      websocketSet({
                        Type: WSProtocol.AccountVerifyEmailUnique,
                        Str1: email,
                      })
                    );
                  }
                  dispatch(
                    formSet({
                      ...formData,
                      email: email || `${formData.username}@example.com`,
                    })
                  );
                  if (email) {
                    if (noEmail) {
                      setNoEmail(false);
                    }
                  } else {
                    setNoEmail(true);
                  }
                }}
                validateText={(str) =>
                  !emailTaken && isStrictEmail(str) && str.length < 50
                }
                strictText={(str) => !str.includes("\n") && !str.includes(" ")}
                helperText={
                  skin.ommitedFields?.emailHelperText
                    ? undefined
                    : t(
                        emailTaken
                          ? "emailTaken"
                          : formData.email.length > 50
                          ? "emailLongHelperText"
                          : "emailHelperText"
                      )
                }
              />
            )}
            <PhoneWithVerificationField
              label={"Phone Number"}
              onChange={(phoneNumber) => {
                dispatch(
                  websocketSet({
                    type: WSProtocol.AccountVerifyPhoneUnique,
                    Str1: phoneNumber,
                  })
                );
                dispatch(formSet({ ...formData, phoneNumber }));
              }}
              onClick={() => {
                if (validity.phoneTaken) {
                  addToast(<p>{`${t("alertTakenPhone")}\n`}</p>, {
                    appearance: "warning",
                    autoDismiss: true,
                  });
                  return;
                }
                dispatch(
                  websocketSet({
                    type: WSProtocol.RequestPhoneCode,
                    Str1: formData.username,
                    Str2: formData.email,
                    Str3: formData.phoneNumber,
                  })
                );
                // dispatch(requestPhoneVerificationSuccess());
              }}
            />
            {phoneVerificationRequested && (
              <FormElement
                label={t("phoneVerificationCodeField")}
                mandatory={true}
                onChange={(code) => dispatch(formSet({ ...formData, code }))}
                defaultValue={formData.code}
              />
            )}
            {(skin.termsOfService ||
              skin.termsOfServiceURL ||
              skin.termsOfServiceCheckbox) && <TermsOfService />}
            {skin.over18Checkbox ? <Over18 /> : null}

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Typography
                gutterBottom
                color={"textPrimary"}
                style={{ fontFamily: "arial" }}
              >
                {t("mandatoryLabel")}
              </Typography>
            </Box>

            <Divider />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 4,
              }}
            >
              {skin.cancelUrl ? (
                <Button
                  style={{
                    marginLeft: 5,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  variant={"outlined"}
                  onClick={() => {
                    skin.cancelUrl && visitUrl(skin.cancelUrl);
                  }}
                >
                  {t("cancel")}
                </Button>
              ) : null}
              <NextStep />
            </Box>
          </Box>
        </Grid>
        <Grid item xl={5} lg={4} md={3} sm={2} xs={1}></Grid>
      </Grid>
      <FormDialog />
    </div>
  );
};
