import { combineReducers } from "redux";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { ActionType } from "typesafe-actions";
import { dateReviver } from "../../global/date-reviver/date-reviver";
import { WSProtocol } from "../websocket/websocket.constant";
import * as actions from "./form.action";
import {
  ACCOUNT_CONNECT,
  ACCOUNT_CREATE,
  EMAIL_VERIFY,
  FormData,
  FORM_CLEAR,
  FORM_SET,
  FORM_UNIQUE_EMAIL_SET,
  LOCK_AGENT_CODE,
  PHONE_VERIFY,
  REQUEST_PHONE_VERIFICATION_FAIL,
  REQUEST_PHONE_VERIFICATION_SUCCESS,
  SUBMIT_PHONE_VERIFICATION_FAIL,
  WEBSOCKET_CONNECT,
  WEBSOCKET_SET,
} from "./form.constants";

export let webSocketSubject: WebSocketSubject<any> | null = null;

type FormState = Readonly<{
  formState: {
    phoneMustBeVerified: boolean;
    emailMustBeVerified: boolean;
    uniqueEmail: boolean;
    phoneVerificationRequested: boolean;
    lockAgentCode: boolean;
    form: FormData;
  };
}>;

type FormAction = ActionType<typeof actions>;

const initialState: FormState = {
  formState: {
    phoneMustBeVerified: false,
    emailMustBeVerified: false,
    uniqueEmail: true,
    phoneVerificationRequested: false,
    lockAgentCode: false,
    form: {
      skin: "",
      username: "",
      email: "",
      password: "",
      repeatPassword: "",
    },
  },
};

export const formReducer = combineReducers<FormState, FormAction>({
  formState: (state = initialState.formState, action) => {
    switch (action.type) {
      case LOCK_AGENT_CODE:
        return { ...state, lockAgentCode: true };
      case FORM_UNIQUE_EMAIL_SET:
        return { ...state, uniqueEmail: action.payload };
      case PHONE_VERIFY:
        return { ...state, phoneMustBeVerified: action.payload };
      case EMAIL_VERIFY:
        return { ...state, emailMustBeVerified: action.payload };
      case ACCOUNT_CREATE: {
        if (!state.uniqueEmail) {
          webSocketSubject?.next({
            Type: WSProtocol.AccountCreate,
            MemberProfile: {
              ...state.form,
              Country: state.form.countryIsoCode,
              FirstName: state.form.name,
              LastName: state.form.surname,
              email: `${state.form.username}${Math.random()
                .toString(36)
                .substring(7)}`,
              ReferalCode: state.form.referralCode || state.form.skin,
              Phone: state.form.phoneNumber,
              DateBirth: state.form.birthDate,
            },
          });
          return state;
        }
        webSocketSubject?.next({
          Type: WSProtocol.AccountCreate,
          MemberProfile: {
            ...state.form,
            Country: state.form.countryIsoCode,
            FirstName: state.form.name,
            LastName: state.form.surname,
            ReferalCode: state.form.referralCode || state.form.skin,
            Phone: state.form.phoneNumber,
            DateBirth: state.form.birthDate,
          },
        });
        return state;
      }
      case ACCOUNT_CONNECT: {
        webSocketSubject?.next({
          Type: WSProtocol.Connect,
          Str1: state.form.username,
          Str2: state.form.password,
        });
        return state;
      }
      case FORM_SET:
        return { ...state, form: action.payload };
      case FORM_CLEAR:
        return {
          ...state,
          uniqueEmail: true,
          phoneMustBeVerified: false,
          phoneVerificationRequested: false,
          form: {
            skin: "",
            username: "",
            email: "",
            password: "",
            repeatPassword: "",
          },
        };
      case WEBSOCKET_CONNECT: {
        webSocketSubject = webSocket({
          url: action.payload.wsUrl,
          deserializer: ({ data }) => JSON.parse(data, dateReviver),
        });
        return state;
      }
      case WEBSOCKET_SET: {
        webSocketSubject?.next(action.payload);
        return state;
      }
      // case CLEAR_DIALOG: {
      //   return {
      //     ...state,
      //     dialog: "",
      //   };
      // }
      case REQUEST_PHONE_VERIFICATION_SUCCESS: {
        return {
          ...state,
          phoneVerificationRequested: true,
        };
      }
      // case REQUEST_PHONE_VERIFICATION_FAIL: {
      //   return {
      //     ...state,
      //     dialog: action.payload,
      //   };
      // }
      // case SUBMIT_PHONE_VERIFICATION_FAIL: {
      //   return {
      //     ...state,
      //     dialog: action.payload,
      //   };
      // }
      default:
        return state;
    }
  },
});
